import React, { useContext } from 'react'
import { API } from 'aws-amplify'
import { GlobalStateContext } from '../context/GlobalContextProvider'
import { Button, ButtonGroup, Box, IconButton, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'

export default function Counter({ setEditUsage, setUsagePending }) {
  const { gStripe, gCompanyEmployee } = useContext(GlobalStateContext)
  const plus = () => {
    gStripe.setCount(gStripe.count + 1)
  }
  const minus = () => {
    if (gStripe.count >= 0) {
      gStripe.setCount(gStripe.count - 1)
    }
  }

  const addUsage = () => {
    setUsagePending(true)
    API.post('Attendance', `/usage`, {
      body: {
        subId: gStripe.customer.subscriptions.data[0].id,
        amount: gStripe.count,
      },
    })
      .then(async () => {
        gStripe.getUsage(gStripe.subscription.id)
        gStripe.getInvoiceComing(gStripe.customer.id)
      })
      .then(() => {
        gStripe.setCount(0)
        setUsagePending(false)
        setEditUsage(false)
      })
      .catch((err) => console.log(err))
  }

  // useEffect(() => {
  // console.log('location', location.pathname.split('/')[1])
  //   if (location.pathname.split('/')[1] === 'newCompany') {
  //     gStripe.setCount(1)
  //   }
  // }, [location.pathname])
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography sx={{ ml: 2 }}>追加人数：</Typography>
      <ButtonGroup
        variant='outlined'
        aria-label='outlined primary button group'
      >
        <Button
          onClick={minus}
          disabled={
            (gStripe.subscription.items.data[0].plan.id ===
              'price_1LkMnTG8znjDYwn9HXn1ytpU' &&
              gStripe.count <= gCompanyEmployee.employees.count) ||
            gStripe.count <= 0
          }
        >
          <RemoveIcon />
        </Button>
        <Box
          sx={{
            border: '1px solid #68c6c8',
            display: 'flex',
            alignItems: 'center',
            p: '0 15px',
          }}
        >
          {gStripe.count}
        </Box>
        <Button
          onClick={plus}
          disabled={
            gStripe.subscription.items.data[0].plan.id ===
              'price_1LkMp0G8znjDYwn9iHDBddLn' &&
            gStripe.usage + gStripe.count >= 5
          }
        >
          <AddIcon />
        </Button>
      </ButtonGroup>
      {/* 確認按鈕組合 */}
      <>
        <IconButton
          sx={{ alignSelf: 'center' }}
          size='small'
          onClick={() => {
            addUsage()
          }}
        >
          <CheckCircleOutlineRoundedIcon fontSize='inherit' />
        </IconButton>
        <IconButton
          sx={{ alignSelf: 'center' }}
          size='small'
          onClick={() => {
            setEditUsage(false)
            gStripe.setCount(0)
          }}
        >
          <CancelOutlinedIcon fontSize='inherit' />
        </IconButton>
      </>
    </Box>
  )
}
