import React, { useState, useEffect } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import getStripe from '../utils/stripe'
import CreditCardInfo from './creditCardInfo'
import { Box } from '@mui/material'
import { API } from 'aws-amplify'
export default function AddCard({
  setOpenCardModal,
  customerID,
  upgrade,
  priceID,
  planToBe,
}) {
  const [clientSecret, setClientSecret] = useState('')

  useEffect(() => {
    if (customerID) {
     getCardInfo(customerID)
    }

  //  console.log('测试数据',customerID)
  }, [customerID])

  const getCardInfo = (customer) => {
    API.post('Attendance', `/credit_card/intent`, {
      body: {
        customer,
      },
    })
      .then((res) => {

    //    console.log("测试数据",res)


        setClientSecret(res)
      })
      .catch((err) =>{
        console.error("测试数据",err)
      })
  }
  const  appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#28aeb1',
    },
  }
  const options = {
    clientSecret,
    appearance,
  }
  return (
    <Box>
      {clientSecret && (
        <Elements options={options} stripe={getStripe()}>
          <CreditCardInfo
            setOpenCardModal={setOpenCardModal}
            priceID={priceID}
            upgrade={upgrade}
            planToBe={planToBe}
          />
        </Elements>
      )}
    </Box>
  )
}
