import React, { useContext, useState, useRef } from 'react'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { GlobalStateContext } from '../../context/GlobalContextProvider'
import LoadingButton from '@mui/lab/LoadingButton'
export default function CreditCardInfo({
  setOpenCardModal,
  priceID,
  upgrade,
  planToBe,
}) {
  const { gCreditCard, gStripe } = useContext(GlobalStateContext)
  const stripe = useStripe()
  const elements = useElements()
  const btn = useRef(null)
  const [loading, setLoading] = useState(false)
  const cardStyles = {
    minWidth: '450px',
    alignSelf: 'center',
    margin: '10px auto 0px auto',
    borderRadius: ' 5px',
    padding: '20px',
  }
  const buttonStyles = {
    height: '42px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#28aeb1',
    fontFamily: 'Arial, sans-serif',
    color: '#ffffff',
    borderRadius: '4px',
    border: 0,
    padding: '10px',
    fontSize: '16px',
    fontWeight: 600,
    cursor: 'pointer',
    transition: ' all 0.2s ease',
  }
  const handleSubmit = (e) => {

  //  console.log("准备提交")
    setLoading(true)
    if (e && e.preventDefault) {
      e.preventDefault()
    }
    stripe
      .confirmSetup({
        elements,
        redirect: 'if_required',
      })
      .then((res) => {
        if (res.setupIntent && res.setupIntent.status === 'succeeded') {
          gStripe.setCustomer({
            ...gStripe.customer,
            invoice_settings: {
              default_payment_method: res.setupIntent.payment_method,
            },
          })
          if (btn.current.innerHTML.includes('アップグレード')) {
            upgrade(priceID)
          }
        }
      })

      .then(() => {
        setOpenCardModal(false)
        setLoading(false)
      })
      .then(() => {
        // if (gStripe.customer.id) {
        // setTimeout(() => {
        gCreditCard.getCreditCard(gStripe.customer.id) // 拿到卡片資訊
        // }, 5000)
        // }
      })
      .catch((err) => {
   //     console.log("发生错误",err)
      })
  }
  const buttonContent = (planToBe, card) => {
    // switch ((planToBe, card)) {
    //   case ('PRO', 0):
    //     return '添加卡片並升級方案PRO'
    //   case ('BUSINESS', 0):
    //     return '添加卡片並升級方案BIZ'
    //   case (undefined, 0):
    //     return '添加卡片初始'
    //   default:
    //     return '添加卡片'
    // }
    if (planToBe === 'PRO' && card === 0) {
      return 'クレジットカードを追加しアップグレード'
    } else if (planToBe === 'BUSINESS' && card === 0) {
      return 'クレジットカードを追加しアップグレード'
    } else if (!planToBe && card === 0) {
      return 'クレジットカードを追加'
    } else {
      return 'クレジットカードを追加'
    }
  }

  return (
    <div style={cardStyles} id='card'>
      <PaymentElement />
      <LoadingButton
        ref={btn}
        loading={loading}
        style={buttonStyles}
        disabled={!stripe || !elements}
        sx={{
          color: loading ? 'transparent !important' : 'white !important',
          '> div > span.MuiCircularProgress-root': {
            width: '20px !important',
            height: '20px !important',
            color: 'white',
          },
        }}
        onClick={() => {
          handleSubmit()
        }}
      >
        {buttonContent(planToBe, gCreditCard.creditCardList.length)}
        {/* {JSON.stringify(planToBe) +
          JSON.stringify(gCreditCard.creditCardList.length)} */}
      </LoadingButton>
    </div>
  )
}
